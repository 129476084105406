import React from "react";
import Hero from "../components/Hero";
import Highlights from "../components/Highlights";
import Reviews from "../components/Reviews";
import About from "../components/About";

export default function HomePage() {
  return (
    <>
      <Hero />
      <Highlights />
      {/* <Reviews /> */}
      <About />
    </>
  );
}
