import React from "react";
import { Tooltip } from "bootstrap";

import { SVGShapeEight } from "../svg/svg-shape-eight";
import { SVGShapeThree } from "../svg/svg-shape-three";
import { SVGShapeOne } from "../svg/svg-shape-one";

import logos from "../data/logos-two.json";
import pricingOne from "../data/pricing-one.json";
import pricingTwo from "../data/pricing-two.json";
import pricingThree from "../data/pricing-three.json";

export default function PricingPage() {
  (function () {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  })();

  return (
    <section>
      <header className="bg-primary py-10 text-white overflow-hidden position-relative z-index-10">
        <div className="container">
          <div className="f-w-72 position-absolute top-n25 start-n13 opacity-10 d-none d-lg-block">
            <span className="d-block">
              <SVGShapeEight
                colorOne={"text-success"}
                colorTwo={"text-success"}
              />
            </span>
          </div>
          <div className="f-w-72 position-absolute bottom-10 end-n3 opacity-10 d-none d-lg-block">
            <span className="d-block">
              <SVGShapeThree
                colorOne={"text-success"}
                colorTwo={"text-success"}
              />
            </span>
          </div>
          <div className="text-center col-12 col-sm-9 col-lg-7 col-xl-6 mx-auto pb-8 position-relative z-index-20">
            <h1 className="display-3 fw-bold mb-3">Simple pricing</h1>
            <p className="opacity-75 lead">
              Try Sigma for free for 30 days with full features, no credit card
              required, no commitment required. Then upgrade your account after
              that when you're ready.
            </p>
            <a href="#" className="btn btn-white mt-4 w-100 w-md-auto">
              Start your trial
            </a>
          </div>
        </div>
      </header>

      {/* Pricing Table */}
      <div className="container position-relative z-index-20">
        <div className="row border mt-n10 bg-white shadow-lg">
          {/* First table */}
          <div className="col-4 d-none d-lg-block border-end">
            {/* Col header */}
            <div className="pricing-table-heading justify-content-start">
              <h3 className="fs-2 fw-bold">Try one of our flexible plans</h3>
              <ul className="list-unstyled mt-4 fs-7">
                <li className="me-4 mb-2 d-flex align-items-center">
                  {/* <i className="ri-checkbox-circle-fill text-success ri-lg me-1"></i> */}
                  <i class="bi bi-check2-circle text-success fs-2 me-1"></i>
                  No credit card required
                </li>
                <li className="me-4 mb-2 d-flex align-items-center">
                  {/* <i className="ri-checkbox-circle-fill text-success ri-lg me-1"></i> */}
                  <i class="bi bi-check2-circle text-success fs-2 me-1"></i>
                  Cancel anytime
                </li>
                <li className="me-4 mb-2 d-flex align-items-center">
                  {/* <i className="ri-checkbox-circle-fill text-success ri-lg me-1"></i> */}
                  <i class="bi bi-check2-circle text-success fs-2 me-1"></i>
                  30 day free trial
                </li>
              </ul>
            </div>
            {/* Col rows */}
            {pricingOne.entries.map(({ tooltip, text, additional_height }) => (
              <div
                class={`${
                  additional_height ? `f-h-24` : null
                } pricing-table-row justify-content-start`}
              >
                <span
                  class="tooltip-trigger"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={tooltip}
                >
                  {text}
                </span>
              </div>
            ))}
          </div>
          {/* Second table */}
          <div className="col-12 col-md-6 col-lg-4">
            {/* Col header */}
            <div className="pricing-table-heading">
              <div>
                <h3 className="fs-1 fw-black">Essential</h3>
                <p className="text-muted">
                  Keep things simple and try our services with our basic plan.
                </p>
                <p className="display-4 lh-1 fw-black mb-1">
                  <sup className="fs-9 align-super me-1">$</sup>
                  19
                  <span className="fs-5 fw-medium">/mo</span>
                </p>
                <span className="d-block text-muted small">
                  Billed annually, per team member
                </span>
              </div>
              <a href="#" className="btn btn-primary w-100 mt-4">
                Get started
              </a>
            </div>
            {/* Col rows */}
            {pricingTwo.entries.map(
              ({ cta, text, suffix, tooltip, additional_height }) => (
                <div
                  class={`${
                    additional_height ? `f-h-24` : null
                  } pricing-table-row`}
                >
                  {cta ? (
                    <a href="#" class="btn btn-primary w-100">
                      {text}
                    </a>
                  ) : (
                    <span
                      class="tooltip-trigger"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={tooltip}
                    >
                      {text} <span class="d-lg-none d-inline">{suffix}</span>
                    </span>
                  )}
                </div>
              )
            )}
          </div>
          {/* Third table */}
          <div className="col-12 col-md-6 col-lg-4 border-start mt-8 mt-md-0">
            {/* Col header */}
            <div className="pricing-table-heading position-relative">
              <div>
                <h3 className="fs-1 fw-black">Agency</h3>
                <p className="text-muted">
                  Perfect for small to mid-sized companies and agencies.
                </p>
                <p className="display-4 lh-1 fw-black mb-1">
                  <sup className="fs-9 align-super me-1">$</sup>
                  45
                  <span className="fs-5 fw-medium">/mo</span>
                </p>
                <span className="d-block text-muted small">
                  Billed annually, per team member
                </span>
              </div>
              <a href="#" className="btn btn-primary w-100 mt-4">
                Get started
              </a>
              <span className="badge bg-orange position-absolute top-0 start-50 translate-middle d-none d-md-flex">
                Most Popular
              </span>
            </div>
            {/* Col rows */}
            {pricingThree.entries.map(
              ({ cta, text, suffix, tooltip, additional_height }) => (
                <div
                  class={`${
                    additional_height ? `f-h-24` : null
                  } pricing-table-row`}
                >
                  {cta ? (
                    <a href="#" class="btn btn-primary w-100">
                      {text}
                    </a>
                  ) : (
                    <span
                      class="tooltip-trigger"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={tooltip}
                    >
                      {text} <span class="d-lg-none d-inline">{suffix}</span>
                    </span>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>

      {/* Entreprise Banner */}
      <div className="container position-relative z-index-20">
        <div className="my-10 bg-primary p-4 p-md-6 rounded text-white d-flex align-items-center position-relative">
          <div className="row">
            <div className="col-12 col-md-5 position-relative z-index-20">
              <h4 className="fs-3 fw-bold mb-3">Need Enterprise?</h4>
              <p className="opacity-75">
                Our Enterprise package is custom-built per client - ideal for
                large businesses.
              </p>
              <a href="#" className="btn btn-white" role="button">
                Discuss your requirements
              </a>
            </div>
            <div className="col-12 col-md-6 offset-md-1 mt-5 mt-lg-0 position-relative z-index-20">
              <ul className="list-unstyled">
                <li className="me-4 mb-2 d-flex align-items-center">
                  <i className="ri-checkbox-circle-fill opacity-75 ri-lg me-1"></i>
                  Custom package per client
                </li>
                <li className="me-4 mb-2 d-flex align-items-center">
                  <i className="ri-checkbox-circle-fill opacity-75 ri-lg me-1"></i>
                  Unlimited resources
                </li>
                <li className="me-4 mb-2 d-flex align-items-center">
                  <i className="ri-checkbox-circle-fill opacity-75 ri-lg me-1"></i>
                  Add additional features
                </li>
              </ul>
            </div>
          </div>
          <div className="position-absolute top-0 end-0 start-0 bottom-0 z-index-0 d-none d-lg-block overflow-hidden">
            <div className="d-block f-w-80 position-absolute top-10 end-n25 opacity-25">
              <span className="d-block">
                <SVGShapeOne colorOne="text-white" colorTwo="text-white" />
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Company Logos */}
      <div className="container position-relative z-index-20">
        <div className="my-10 py-4">
          <div className="container">
            <p className="mb-6 text-center small fw-bolder tracking-wider text-muted text-uppercase">
              Already trusted by thousands of brands
            </p>
            <div className="row gx-8 gy-5 justify-content-center align-items-center">
              {logos.entries.map((logo, index) => (
                <div className="col-6 col-sm-4 col-md-3 col-lg-2">
                  <picture>
                    <img
                      className="img-fluid"
                      src={`${process.env.PUBLIC_URL}/assets/images/${logo.img}`}
                    />
                  </picture>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* FAQ */}
      <div className="py-10 bg-light">
        <div className="container">
          <h3 className="text-center fs-1 mb-3 fw-bold">
            Frequently asked questions
          </h3>
          <div className="mx-auto mt-5 col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="accordion border-0" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header border-bottom" id="headingOne">
                  <button
                    className="accordion-button fw-medium collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Are there any restrictions on the trial offer?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <strong>This is the first item's accordion body.</strong> It
                    is shown by default, until the collapse plugin adds the
                    appropriate classes that we use to style each element. These
                    classes control the overall appearance, as well as the
                    showing and hiding via CSS transitions. You can modify any
                    of this with custom CSS or overriding our default variables.
                    It's also worth noting that just about any HTML can go
                    within the <code>.accordion-body</code>, though the
                    transition does limit overflow.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header border-bottom" id="headingTwo">
                  <button
                    className="accordion-button fw-medium collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    What are the cancellation terms?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <strong>This is the second item's accordion body.</strong>{" "}
                    It is hidden by default, until the collapse plugin adds the
                    appropriate classes that we use to style each element. These
                    classes control the overall appearance, as well as the
                    showing and hiding via CSS transitions. You can modify any
                    of this with custom CSS or overriding our default variables.
                    It's also worth noting that just about any HTML can go
                    within the <code>.accordion-body</code>, though the
                    transition does limit overflow.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2
                  className="accordion-header border-bottom"
                  id="headingThree"
                >
                  <button
                    className="accordion-button fw-medium collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    What payment methods does Sigma accept?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <strong>This is the third item's accordion body.</strong> It
                    is hidden by default, until the collapse plugin adds the
                    appropriate classes that we use to style each element. These
                    classes control the overall appearance, as well as the
                    showing and hiding via CSS transitions. You can modify any
                    of this with custom CSS or overriding our default variables.
                    It's also worth noting that just about any HTML can go
                    within the <code>.accordion-body</code>, though the
                    transition does limit overflow.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header border-bottom" id="headingFour">
                  <button
                    className="accordion-button fw-medium collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Can I upgrade after signing up?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <strong>This is the third item's accordion body.</strong> It
                    is hidden by default, until the collapse plugin adds the
                    appropriate classes that we use to style each element. These
                    classes control the overall appearance, as well as the
                    showing and hiding via CSS transitions. You can modify any
                    of this with custom CSS or overriding our default variables.
                    It's also worth noting that just about any HTML can go
                    within the <code>.accordion-body</code>, though the
                    transition does limit overflow.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
