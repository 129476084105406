import React from "react";
import TeamGrid from "../components/TeamGrid";

export default function AboutPage() {
  return (
    <div>
      <header className="pt-10 text-center col-12 col-sm-9 col-lg-7 col-xl-6 mx-auto">
        <h1 className="display-3 fw-bold mb-3">Qui nous sommes ?</h1>
        <p className="text-muted lead mb-0">
          Créer en 2017, FATIHOUNE Sarl a pour mission d'apporter une assistance
          pluridisciplinaire dans l'amélioration des compétences des entreprises
          et des administrations partout dans le monde
        </p>
      </header>
      <div class="container py-7">
        <div class="row g-3">
          <div class="col-12 d-none d-md-block">
            <div class="row g-3">
              <div class="col-12 col-md-4">
                <picture>
                  <img
                    class="img-fluid rounded shadow-sm"
                    src={`${process.env.PUBLIC_URL}/assets/images/rabat.jpg`}
                    alt=""
                  />
                </picture>
              </div>
              <div class="col-12 col-md-4">
                <picture>
                  <img
                    class="img-fluid rounded shadow-sm mb-3"
                    src={`${process.env.PUBLIC_URL}/assets/images/abidjan.jpg`}
                    alt=""
                  />
                </picture>
              </div>
              <div class="col-12 col-md-4">
                <picture>
                  <img
                    class="img-fluid rounded shadow-sm"
                    src={`${process.env.PUBLIC_URL}/assets/images/paris.jpg`}
                    alt=""
                  />
                </picture>
              </div>
            </div>
          </div>
          {/* <div class="col-12 col-md-6">
            <div class="row g-3">
              <div class="col-12 col-md-6 d-none d-md-block">
                <picture>
                  <img
                    class="img-fluid rounded shadow-sm"
                    src={`${process.env.PUBLIC_URL}/assets/images/paris.jpg`}
                    alt=""
                  />
                </picture>
              </div>
              <div class="col-12 col-md-6">
                <picture>
                  <img
                    class="img-fluid rounded shadow-sm mb-3"
                    src={`${process.env.PUBLIC_URL}/assets/images/russie.jpeg`}
                    alt="{{ config.defaultImgAlt }}"
                  />
                </picture>
                <picture>
                  <img
                    class="img-fluid rounded shadow-sm"
                    src={`${process.env.PUBLIC_URL}/assets/images/chine.jpeg`}
                    alt=""
                  />
                </picture>
              </div>
            </div>
          </div> */}
        </div>

        <div className="col-12 col-md-8 col-lg-6 mx-auto text-center py-4 border-bottom mb-5">
          <div className="my-5 d-none d-md-flex align-items-start justify-content-between">
            <div>
              <span className="display-3 fw-bold text-primary d-block">3</span>
              <span className="d-block fs-9 fw-bolder tracking-wide text-uppercase text-muted">
                Pays
              </span>
            </div>
            <div>
              <span className="display-3 fw-bold text-primary d-block">
                +5K
              </span>
              <span className="d-block fs-9 fw-bolder tracking-wide text-uppercase text-muted">
                Clients
              </span>
            </div>
            <div>
              <span className="display-3 fw-bold text-primary d-block">
                60
              </span>
              <span className="d-block fs-9 fw-bolder tracking-wide text-uppercase text-muted">
                Employées
              </span>
            </div>
          </div>
        </div>

        <div class="py-6 row gx-8 align-items-center">
          <div class="col-12 col-lg-6">
            <h2 class="display-5 fw-bold mb-6">NOTRE MISSION</h2>
            <p>
              FATIHOUNE Sarl a pour mission d'apporter une assistance
              pluridisciplinaire dans l’amélioration des compétences des
              entreprises et des administrations dans toute l’Afrique. Grâce à
              un rucher d’experts de haut niveau, FATIHOUNE Sarl se positionne
              comme partenaire privilégié des entreprises dans divers domaines
              de compétences. Nous contribuons ainsi au développement de votre
              entreprise avec pour objectif l’amélioration de votre performance
              et de votre compétitivité sur le plan national et international.
            </p>
          </div>
          <div class="col-12 col-lg-6">
            <picture>
              <img
                class="img-fluid rounded shadow-sm"
                src={`${process.env.PUBLIC_URL}/assets/images/mission.jpg`}
                alt=""
              />
            </picture>
          </div>
        </div>

        <div class="py-6 row gx-8 align-items-center">
          <div class="col-12 col-lg-6">
            <picture>
              <img
                class="img-fluid rounded shadow-sm"
                src={`${process.env.PUBLIC_URL}/assets/images/objectif.jpg`}
                alt=""
              />
            </picture>
          </div>
          <div class="col-12 col-lg-6">
            <h2 class="display-5 fw-bold mb-6">NOS OBJECTIFS</h2>
            <ul>
              <li>
                Contribuer au développement des entreprises par le renforcement
                de leur performance et de leur compétitivité
              </li>
              <li>
                Offrir aux clients des prestations de qualité et adaptées à
                leurs besoins
              </li>
              <li>
                Développer des solutions formations sur mesure et fournir une
                assistance personnalisée
              </li>
              <li>
                Aider à l’alignement du système d’information à la stratégie de
                l’entreprise et en faire un autre levier de la productivité et
                de la croissance
              </li>
              <li>
                Eclairer les entreprises et/ou leurs dirigeants dans la prise de
                décision grâce à nos activités de conseils
              </li>
            </ul>
          </div>
        </div>

        <div class="py-6 row gx-8 align-items-center">
          <div class="col-12 col-lg-6">
            <h2 class="display-5 fw-bold mb-6">NOS VALEURS</h2>
            <ul>
              <li> Agir efficacement et durablement </li>
              <li>
                Proposer une qualité de service tout en tenant compte du cout de
                la prestation et de son délai d’exécution
              </li>
              <li>
                Conformer le cabinet aux standards internationaux tout en
                multipliant les opportunités d’emploi et d’avancement.
              </li>
            </ul>
          </div>
          <div class="col-12 col-lg-6">
            <picture>
              <img
                class="img-fluid rounded shadow-sm"
                src={`${process.env.PUBLIC_URL}/assets/images/value.jpg`}
                alt=""
              />
            </picture>
          </div>
        </div>

        <div class="py-6 row gx-8 align-items-center">
          <div class="col-12 col-lg-6">
            <picture>
              <img
                class="img-fluid rounded shadow-sm"
                src={`${process.env.PUBLIC_URL}/assets/images/atout.jpg`}
                alt=""
              />
            </picture>
          </div>
          <div class="col-12 col-lg-6">
            <h2 class="display-5 fw-bold mb-6">NOS ATOUTS</h2>
            <ul>
              <li> Rucher d’experts de hauts niveaux </li>
              <li>
                Qualité des interventions et alignement sur les standards
                internationaux
              </li>
              <li> Matériel et expertise adaptés aux besoins des clients.</li>
            </ul>
          </div>
        </div>

        {/* <TeamGrid /> */}

        <div class="d-flex justify-content-center my-5">
          <div class="rounded-pill border px-5 py-3 text-muted d-flex align-items-center">
            Vous souhaitez rejoindre notre équipe ?
            <a href="#" class="fw-bold d-flex align-items-center ms-2">
              Nous recrutons <i class="ri-arrow-right-line ms-1"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
