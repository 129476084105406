import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// import SERVICES from "../data/services.json";
import { useApi } from "../contexts/ApiProvider";
import { useFlash } from "../contexts/FlashProvider";

export default function Navbar() {
  const navigate = useNavigate();
  const api = useApi();
  const flash = useFlash();

  const [services, setServices] = useState([]);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/services/search`);
      if (response.success) {
        setServices(response?.services);
      } else {
        flash(`${response.error.message}`, "danger");
      }
    })();
  }, [api]);

  useEffect(() => {
    (() => {
      const megaMenuToggles =
        document.querySelectorAll(".nav-item.dropdown") || [];
      const cssVarBreakPoint =
        getComputedStyle(document.documentElement).getPropertyValue(
          "--theme-breakpoint-lg"
        ) || "992px";
      const breakpointLG = parseInt(cssVarBreakPoint, 10);

      function showMenu(event) {
        const dropdown = event.target.querySelector(".dropdown-menu");
        const menuToggle = event.target.querySelector(".dropdown-toggle");

        if (window.innerWidth < breakpointLG) {
          return;
        }

        if (dropdown && menuToggle) {
          dropdown.classList.add("show");
          menuToggle.setAttribute("aria-expanded", "true");
          menuToggle.classList.add("show");
        }
      }

      function hideMenu(event) {
        const dropdown = event.target.querySelector(".dropdown-menu");
        const menuToggle = event.target.querySelector(".dropdown-toggle");

        if (window.innerWidth < breakpointLG) {
          return;
        }

        if (dropdown && menuToggle) {
          dropdown.classList.remove("show");
          menuToggle.setAttribute("aria-expanded", "false");
          menuToggle.classList.remove("show");
        }
      }

      megaMenuToggles.forEach((toggle) => {
        toggle.addEventListener("mouseenter", (event) => {
          showMenu(event);
        });
        toggle.addEventListener("mouseleave", (event) => {
          hideMenu(event);
        });
      });
    })();
  }, []);

  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light border-bottom">
      <div className="container">
        <Link
          className="navbar-brand d-flex align-items-center lh-1 me-10 transition-opacity opacity-75-hover"
          to={`/`}
        >
          <img
            className="w-50 h-50"
            src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
            alt=""
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-between align-items-center"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav">
            <li className="nav-item position-static dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Produits & Services
              </a>
              <div className="dropdown-menu dropdown-megamenu">
                <div className="container">
                  <div className="row py-lg-5 gx-8">
                    {services?.map((service, index) => (
                      <div
                        key={index}
                        className="col-auto col-lg-4 mb-4 mb-lg-5 d-flex align-items-start"
                      >
                        <div className="position-relative">
                          <h6 className="dropdown-heading">{service?.title}</h6>
                          <p className="text-muted">{service?.description}</p>
                          <Link
                            className="fw-medium fs-7 text-decoration-none link-cover"
                            to={`services/${service?.slug}`}
                          >
                            En savoir plus &rarr;
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item">
              <Link to={`/about`} className="nav-link">
                Qui nous sommes
              </Link>
            </li>
            <li className="nav-item">
              <Link to={`/contact`} className="nav-link">
                Contact
              </Link>
            </li>
          </ul>

          <div className="d-none d-lg-flex">
            <Link
              to={`/contact`}
              className="btn btn-primary ms-2"
              role="button"
            >
              Contactez-Nous
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}
