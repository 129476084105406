import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import AppLayout from "./components/AppLayout";
import ContactPage from "./pages/ContactPage";
import PricingPage from "./pages/PricingPage";
import ServicePage from "./pages/ServicePage";
import ScrollToTop from "./components/ScrollToTop";
import ApiProvider from "./contexts/ApiProvider";

export default function App() {
  return (
    <>
      <ApiProvider>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route path="" element={<HomePage />} />
            <Route path="services/:slug" element={<ServicePage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="pricing" element={<PricingPage />} />
          </Route>
        </Routes>
      </ApiProvider>
    </>
  );
}
