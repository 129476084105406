import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useApi } from "../contexts/ApiProvider";
import { useFlash } from "../contexts/FlashProvider";
import DOMPurify from "dompurify";

export default function ServicePage() {
  const navigate = useNavigate();
  const api = useApi();
  const flash = useFlash();

  const [service, setService] = useState();

  const { slug } = useParams();

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/services/slug/${slug}`);
      if (response.success) {
        setService(response?.service);
      } else {
        flash(`${response.error.message}`, "danger");
      }
    })();
  }, [api, slug]);

  return (
    <div>
      <header className="text-center pt-10 col-12 col-sm-9 col-lg-7 col-xl-12 mx-auto">
        <p class="mb-0 small fw-bolder tracking-wider text-uppercase">
          Fatihoune
          <span className="text-primary"> {service?.title}</span>
        </p>
        <h1 className="display-3 fw-bold mb-3">{service?.subtitle}</h1>
        <p className="text-muted lead mb-0">{service?.description}</p>
      </header>
      <div class="container py-7">
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(service?.content),
          }}
        />
        {/* <div class="py-6 row gx-8 align-items-center">
          <div class="col-12 col-lg-6">
            <h2 class="display-5 fw-bold mb-6">Pourquoi Choisir Nos Offres</h2>
            <ul>
              {service?.advantages.map((advantage, index) => (
                <li key={index}>{advantage}</li>
              ))}
            </ul>
          </div>
          <div class="col-12 col-lg-6">
            <picture>
              <img
                class="img-fluid rounded shadow-sm"
                src={`${process.env.PUBLIC_URL}/assets/images/mission.jpg`}
                alt=""
              />
            </picture>
          </div>
        </div> */}

        {/* {service?.services.map((service, index) => (
          <div class="py-6 row gx-8 align-items-center" key={index}>
            <div class="col-12 col-lg-6">
              <picture>
                <img
                  class="img-fluid rounded shadow-sm"
                  src={`${process.env.PUBLIC_URL}/assets/images/${service.image}`}
                  alt=""
                />
              </picture>
            </div>
            <div class="col-12 col-lg-6">
              <h2 class="display-5 fw-bold mb-6">{service.name}</h2>
              <p>{service.description}</p>
            </div>
          </div>
        ))} */}
      </div>
    </div>
  );
}
